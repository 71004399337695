<template>
  <b-toast
      v-if="false"
      visible
      no-close-button
      :auto-hide="false"
      variant="info"
      solid
      toaster="b-toaster-bottom-center"
  >
    <div class="d-flex flex-column text-center">
      <h5 class="mb-3">Download app for the full experience</h5>
      <b-button :href="linkToStore" target="_blank" variant="primary">
        <span v-if="isIos">Download on the App Store</span>
        <span v-else-if="isAndroid">Get it on Google Play</span>
        <span v-else>Get it on AppGallery</span>
      </b-button>
    </div>
  </b-toast>
</template>

<script>
import {handleWebview} from '@core/mixins/ui/handleWebview';
import {mapGetters} from 'vuex';
import {BButton, VBTooltip} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import {$themeConfig} from '@themeConfig';

export default {
  name: 'DownloadAppToast',

  mixins: [handleWebview],

  components: {
    BButton,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  data() {
    return {
      cookieName: 'download_agendum_app',
    }
  },

  mounted() {
  },

  methods: {
    async showToast() {
      let result = await this.$swal({
        title: 'Get the full app experience',
        text: 'Enjoy notification, chat and other features on the app.',
        // eslint-disable-next-line global-require
        imageUrl: this.appLogoImage,
        imageWidth: 80,
        imageAlt: 'Logo',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        customClass: {
          confirmButton: 'btn btn-primary w-100',
          cancelButton: 'btn btn-primary w-100',
        },
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: 'Download Agendum App',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
      })

      if (result.isConfirmed) {
        window.open(this.downloadAppLink, '_blank');

        this.downloadAppCallback()
      }
    },

    downloadAppCallback() {
      this.$cookies.remove(this.cookieName)
      this.$cookies.set(this.cookieName, {
        download: {
          clicked: true,
        },
        timestamp: Date.now()
      })
    },
  },

  computed: {
    ...mapGetters('auth', ['user', 'isLoggedIn']),
    ...mapGetters('app', ['currentBreakPoint']),

    isMobileBreakPoint() {
      return this.currentBreakPoint === 'sm' || this.currentBreakPoint === 'xs'
    },

    isDesktopBreakpoint() {
      return this.currentBreakPoint === 'lg' || this.currentBreakPoint === 'xl'
    },

    shouldShowToast() {
      if (this.cookieApp && this.cookieApp.download.clicked === true) {
        const storedTime = this.cookieApp.timestamp;
        const oneHourAgo = Date.now() - 3600000; // 1 hour in milliseconds

        if (storedTime > oneHourAgo) return false
      }

      return process.env.NODE_ENV === 'production' &&
          this.isLoggedIn &&
          !this.isDesktopBreakpoint &&
          !this.isStandalone() &&
          !this.isWebview() &&
          this.$route.path !== '/'
    },

    appLogoImage() {
      const { appLogoImage } = $themeConfig.app
      return appLogoImage
    },

    downloadAppLink() {
      if (this.isIos()) return this.appStoreLink

      if (this.isAndroid()) return this.googlePlayLink

      return this.appGalleryLink
    },

    routeName() {
      return this.$route.name
    },

    cookieApp() {
      return this.$cookies.get(this.cookieName)
    },
  },

  watch: {
    shouldShowToast(to, from) {
      if (to) {
        this.showToast()
      }
    },

    async routeName(to, from) {
      if (this.shouldShowToast) {
        await this.$nextTick()
        await this.showToast()
      }
    }
  },
};
</script>

<style scoped lang="scss">

</style>
