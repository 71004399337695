export const handleWebview = {
  data() {
    return {
      appStoreLink: 'https://apps.apple.com/ro/app/agendum-club/id6444837205',
      googlePlayLink: 'https://play.google.com/store/apps/details?id=com.agendum',
      appGalleryLink: 'https://appgallery.huawei.com/app/C112414739',
    }
  },

  methods: {
    isAndroid() {
      return /android/i.test(this.userAgent);
    },

    isIos() {
      return /iphone|ipod|ipad/.test(this.userAgent)
    },

    isSafari() {
      return /safari/.test(this.userAgent)
    },

    isStandalone() {
      return  window.matchMedia('(display-mode: standalone)').matches
          || window.navigator.standalone
    },

    isWebview() {
      if (this.isIos()) {
        if (this.isStandalone() && this.isSafari()) {
          return true
        }
      } else {
        if (this.userAgent.includes('wv')) {
          return true
        }
      }

      return false
    },
  },

  computed: {
    userAgent() {
      return window.navigator.userAgent.toLowerCase()
          || window.navigator.vendor?.toLowerCase()
          || window.opera?.toLowerCase();
    },

    linkToStore() {
      if (this.isIos()) {
        return this.appStoreLink;
      } else if (this.isAndroid()) {
        return this.googlePlayLink;
      } else {
        return this.appGalleryLink;
      }
    }
  },
  watch: {},
}

export const _ = null
